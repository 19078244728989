import React from 'react';
import Nav from "../../../component/nav";
import Footer from "../../../component/footer";
import Home from "./Home";

const HomeIndex = () => {
  return (
    <>
      <Nav />
      <Home />
      <Footer />
    </>
  )
}

export default HomeIndex