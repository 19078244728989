import React from 'react';
import {
    Grid,
    makeStyles,
    Box
} from '@material-ui/core';
import { useHistory } from "react-router-dom"




const data = [
    {
        img: "/images/PAW-logo1.svg",
        title: "PAW PRICE",
        dollar: "$0.88",
        price: "@ 0.000033 BTC"
    },
    {
        img: "/images/block.svg",
        title: "LATEST BLOCK",
        dollar: "$0.88",
        price: "@ 0.000033 BTC"
    },
    {
        img: "/images/PAW-logo1.svg",
        title: "PAW PRICE",
        dollar: "$0.88",
        price: "@ 0.000033 BTC"
    }
]
const useStyles = makeStyles((theme) => ({
    mainBox: {
        backgroundColor: "#FFFFFF",
        // height:"560px"
    },
    absolutePosition: {
        position: "relative",
        bottom: "59px"
    },
    blocks: {
        display: "flex",
        justifyContent: "center"
    },
    Indexblock: {
        background: "#FFFFFF",
        borderRadius: "5px",
        height: "107px",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)"
    },
    indexblockFlex: {
        display: "flex",
        alignItems: "center",
        gap: "14px",
        padding: "3px 25px"
    },
    indexblockText: {
        display: "flex",
        flexDirection: "column",
        "& h1": {
            fontFamily: 'Poppins',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "00.1em",
            color: "#D64F49"
        },
        "& p": {
            display: "flex",
            fontFamily: 'Poppins',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "00.1em",
            color: "#111111",
            marginTop: "0"
        },
        "& span": {
            fontFamily: 'Poppins !important',
            fontStyle: "normal !important",
            fontWeight: "600 !important",
            fontSize: "14px !important",
            lineHeight: "18px !important",
            letterSpacing: "00.1em !important",
            color: "#77838F !important"
        }
    },
    redloss: {
        color: "#111111 !important",
    },
    transactionFlex: {
        marginTop: "50px",
        padding: "0 4rem"
    },
    tableContainer: {
        background: "#FFFFFF",
        border: "1px solid #E7EAF3",
        borderBottom: "none",
        width: "100%",
        borderTop: "none",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)"
    },
    tableTitle: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "00.1em",
        color: "#111111",
    },
    tableRow: {
        borderBottom: "1.0155px solid #E7EAF3",
        borderRadius: "7px 7px 0px 0px",
        padding: "15px !important"
    },
    tableData: {
        padding: "10px"
    },
    tableDtaFlex: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
    },
    tableTDInnerFlex: {
        display: "flex",
        flexDirection: "column",
        "& p": {
            fontFamily: 'Poppins',
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#D64F49",
            margin: "0"
        },
        "& span": {
            fontFamily: 'Poppins',
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "11px",
            lineHeight: "17px",
            color: "#77838F"
        }
    },
    tableSymbol: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "0px",
        color: "#111111",
        background: "rgba(119, 131, 143, 0.1)",
        borderRadius: "5.6826px",
        width: "44.4px",
        height: "44.4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    transactionSymbol: {
        borderRadius: "22px"
    },
    tableTDMiddleFlex: {
        display: "flex",
        flexDirection: "column",
        "& p": {
            fontFamily: 'Poppins',
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "##111111",
            margin: "0"
        },
    },
    lastBlock: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "10px",
        lineHeight: "18px",
        color: "#77838F",
        background: "rgba(119, 131, 143, 0.1)",
        borderRadius: "5.07375px",
        height: "39px"
    },
    btnouterdiv: {
        border: "1px solid #E7EAF3",
        borderRadius: "0px 0px 7px 7px",
        padding: " 10px",
        background: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)"

    },
    blockBtnDiv: {
        background: "#D64F49",
        borderRadius: " 4px",
        textAlign: "center"
    },
    blockbtn: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px 0",
        cursor: "pointer"
    },
    btnUpperouterdiv: {
        background: "#FFFFFF",
        border: "1px solid #E7EAF3",
        borderRadius: "8px 8px 0px 0px",
        padding: "0 15px",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)"
    },
}));



const Home = () => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <>
            <Grid className={classes.mainBox}>
                <Box className={classes.absolutePosition}>
                    <Grid item container spacing={4} className={classes.blocks} style={{ gap: 30 }}>
                        {data.map(key => {
                            return (
                                <Grid item xs={8} sm={6} md={3} className={classes.Indexblock}>
                                    <div className={classes.indexblockFlex}>
                                        <div>
                                            <img src={key.img} alt=""/>
                                        </div>
                                        <div className={classes.indexblockText}>
                                            <h1>{key.title}</h1>
                                            <p>{key.dollar} &nbsp;<span>{key.price}</span> &nbsp;&nbsp;&nbsp;<p className={classes.redloss}>(0.77%)</p></p>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })}

                    </Grid>
                    <Box className={classes.transactionFlex}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={8} className={classes.tableBoxShadow}>
                                <div className={classes.btnUpperouterdiv}>
                                    <p className={classes.tableTitle}>Latest Blocks</p>
                                </div>
                                <table className={classes.tableContainer}>
                                    <tr>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableDtaFlex}>
                                                <div className={classes.tableSymbol}>BK</div>
                                                <div className={classes.tableTDInnerFlex}>
                                                    <p>4308725</p>
                                                    <span>12 secs ago</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableTDMiddleFlex}>
                                                <p>Vaidated By <span style={{ color: "#D64F49" }}>0xc6869257205e20c2a4</span></p>
                                                <p style={{ color: "#D64F49" }}>52 txns &nbsp; <span style={{ color: "#77838F" }}>in 12 secs</span></p>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.lastBlock}>
                                                1.01989 PAW
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableDtaFlex}>
                                                <div className={classes.tableSymbol}>BK</div>
                                                <div className={classes.tableTDInnerFlex}>
                                                    <p>4308725</p>
                                                    <span>12 secs ago</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableTDMiddleFlex}>
                                                <p>Vaidated By <span style={{ color: "#D64F49" }}>0xc6869257205e20c2a4</span></p>
                                                <p style={{ color: "#D64F49" }}>52 txns &nbsp; <span style={{ color: "#77838F" }}>in 12 secs</span></p>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.lastBlock}>
                                                1.01989 PAW
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableDtaFlex}>
                                                <div className={classes.tableSymbol}>BK</div>
                                                <div className={classes.tableTDInnerFlex}>
                                                    <p>4308725</p>
                                                    <span>12 secs ago</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableTDMiddleFlex}>
                                                <p>Vaidated By <span style={{ color: "#D64F49" }}>0xc6869257205e20c2a4</span></p>
                                                <p style={{ color: "#D64F49" }}>52 txns &nbsp; <span style={{ color: "#77838F" }}>in 12 secs</span></p>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.lastBlock}>
                                                1.01989 PAW
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableDtaFlex}>
                                                <div className={classes.tableSymbol}>BK</div>
                                                <div className={classes.tableTDInnerFlex}>
                                                    <p>4308725</p>
                                                    <span>12 secs ago</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableTDMiddleFlex}>
                                                <p>Vaidated By <span style={{ color: "#D64F49" }}>0xc6869257205e20c2a4</span></p>
                                                <p style={{ color: "#D64F49" }}>52 txns &nbsp; <span style={{ color: "#77838F" }}>in 12 secs</span></p>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.lastBlock}>
                                                1.01989 PAW
                                            </div>
                                        </td>
                                    </tr>

                                </table>
                                <div className={classes.btnouterdiv}>
                                    <div className={classes.blockBtnDiv}>
                                        <btn className={classes.blockbtn} onClick={() => { history.push("/blocks") }}>View all blocks</btn>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} sm={8}>
                                <div className={classes.btnUpperouterdiv}>
                                    <p className={classes.tableTitle}>Last Transactions</p>
                                </div>
                                <table className={classes.tableContainer}>
                                    <tr>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableDtaFlex}>
                                                <div className={`${classes.tableSymbol} ${classes.transactionSymbol}`}>BK</div>
                                                <div className={classes.tableTDInnerFlex}>
                                                    <p>0x49e2bac9f870c</p>
                                                    <span>12 secs ago</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableTDMiddleFlex}>
                                                <p>From <span style={{ color: "#D64F49" }}>0xc6869257205e20c2a4</span></p>
                                                <p>To &nbsp; <span style={{ color: "#D64F49" }}>0x2ab0e9e4ee70fff1fb9d</span></p>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.lastBlock}>
                                                1.01989 PAW
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableDtaFlex}>
                                                <div className={`${classes.tableSymbol} ${classes.transactionSymbol}`}>BK</div>
                                                <div className={classes.tableTDInnerFlex}>
                                                    <p>0x49e2bac9f870c</p>
                                                    <span>12 secs ago</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableTDMiddleFlex}>
                                                <p>From <span style={{ color: "#D64F49" }}>0xc6869257205e20c2a4</span></p>
                                                <p>To &nbsp; <span style={{ color: "#D64F49" }}>0x2ab0e9e4ee70fff1fb9d</span></p>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.lastBlock}>
                                                1.01989 PAW
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableDtaFlex}>
                                                <div className={`${classes.tableSymbol} ${classes.transactionSymbol}`}>BK</div>
                                                <div className={classes.tableTDInnerFlex}>
                                                    <p>0x49e2bac9f870c</p>
                                                    <span>12 secs ago</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableTDMiddleFlex}>
                                                <p>From <span style={{ color: "#D64F49" }}>0xc6869257205e20c2a4</span></p>
                                                <p>To &nbsp; <span style={{ color: "#D64F49" }}>0x2ab0e9e4ee70fff1fb9d</span></p>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.lastBlock}>
                                                1.01989 PAW
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableDtaFlex}>
                                                <div className={`${classes.tableSymbol} ${classes.transactionSymbol}`}>BK</div>
                                                <div className={classes.tableTDInnerFlex}>
                                                    <p>0x49e2bac9f870c</p>
                                                    <span>12 secs ago</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.tableTDMiddleFlex}>
                                                <p>From <span style={{ color: "#D64F49" }}>0xc6869257205e20c2a4</span></p>
                                                <p>To &nbsp; <span style={{ color: "#D64F49" }}>0x2ab0e9e4ee70fff1fb9d</span></p>
                                            </div>
                                        </td>
                                        <td className={classes.tableData}>
                                            <div className={classes.lastBlock}>
                                                1.01989 PAW
                                            </div>
                                        </td>
                                    </tr>

                                </table>
                                <div className={classes.btnouterdiv}>
                                    <div className={classes.blockBtnDiv}>
                                        <btn className={classes.blockbtn} onClick={()=>{history.push("/transaction-history")}}>View all blocks</btn>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}

export default Home