import React,{useState} from 'react'
import {
    Grid,
    makeStyles,
    Box,
    Toolbar,
    IconButton,
    Typography,
    TableContainer,
    TableHead,
    Table,
    TableCell,
    Paper,
    TableBody,
    TableRow,

} from '@material-ui/core';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTableCell-head": {
            background: "#FFFFFF",
            borderWidth: "1px 0px 2px 0px !important",
            borderStyle: "solid !important",
            borderColor: "#E7EAF3 !important"
        },
    },
    title: {
        flex: '1 1 100%',
        display: "flex",
        flexDirection: 'column',
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#6C757E",
    },
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        background: "#FFFFFF",
        border: "1px solid rgba(231, 234, 243, 0.2)",
        boxShadow: "0px 8px 20px rgba(65, 65, 65, 0.2)",
        borderRadius: " 8px"
    },
    mainBox: {
        backgroundColor: "#FFFFFF",
        // height:"560px"
        marginBottom: "10rem"
    },
    absolutePosition: {
        position: "relative",
        bottom: "2rem",
        padding: "0 5%"
    },
    blocks: {
        display: "flex",
        justifyContent: "center"
    },
    tableHead: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#111111",
        textAlign: "left"
    },
    tablecell: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#D64F49",
        textAlign: "center"
    },
    iconBorder: {
        borderRadius: "5px !important"
    },
    flexSpan: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px"
    },
    colorPrimary: {
        background: "#E7EAF3",
        width: "100%",
        borderRadius: "5px"
    },
    barColorPrimary: {
        background: "#D64F49"
    },
    colorPrimary2: {
        background: "#E7EAF3",
        width: "100%",
        borderRadius: "5px"
    },
    barColorPrimary2: {
        background: "#DB9A04"
    },
    flexSerial: {
        display: "flex",
        alignItems: "center",
        cursor:"pointer"
    },
    methodCell: {
        background: "rgba(52, 152, 219, 0.55)",
        borderRadius: "5px",
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "10px",
        lineHeight: "18px",
        color: "#FFFFFF",
        padding: "8px 0"
    },
}));

const Transaction = () => {
    const classes = useStyles();
    const [textToCopy, setTextToCopy] = useState("");

    const copyTextToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            toast.success('Text copied to clipboard');
          })
          .catch(() => {
            toast.error('Failed to copy text');
          });
      };

    const EnhancedTableToolbar = () => {

        return (
            <Toolbar
            >
                <Typography className={classes.title} id="tableTitle" component="div">
                    More than 2,726,635,206 transactions found
                    <span>(Showing the last 500k records)</span>
                </Typography>
                <IconButton className={classes.iconBorder}>
                    <Typography>First</Typography>
                </IconButton>
                <IconButton className={classes.iconBorder}>
                    <img src="/images/leftIcon.svg" alt=""/>
                </IconButton>
                <IconButton className={classes.iconBorder}>
                    <Typography>Page 1 of 1724988</Typography>
                </IconButton>
                <IconButton className={classes.iconBorder}>
                    <img src="/images/rightIcon.svg" alt=""/>
                </IconButton>
                <IconButton className={classes.iconBorder} style={{ color: "#D64F49" }}>
                    <Typography>Last</Typography>
                </IconButton>
            </Toolbar>
        );
    };
    return (
        <>
            <Grid className={classes.mainBox}>
                <Box className={classes.absolutePosition}>
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            <EnhancedTableToolbar />
                            <TableContainer>
                                <Table arial-label="simple table">
                                    <TableHead>
                                        <TableRow className={classes.root}>
                                            <TableCell className={classes.tableHead}>
                                                Txn Hash
                                            </TableCell>
                                            <TableCell className={classes.tableHead}>
                                                Method
                                            </TableCell>
                                            <TableCell className={classes.tableHead} style={{ textAlign: "center" }}>
                                                Block
                                            </TableCell>
                                            <TableCell className={classes.tableHead} style={{ textAlign: "center" }}>
                                                Age
                                            </TableCell>
                                            <TableCell className={classes.tableHead}>
                                                From
                                            </TableCell>
                                            <TableCell className={classes.tableHead}>
                                                To
                                            </TableCell>
                                            <TableCell className={classes.tableHead}>
                                                Value
                                            </TableCell>
                                            <TableCell className={classes.tableHead}>
                                                Txn Fee
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.tablecell}>
                                                <div className={classes.flexSerial}>
                                                    <img src="/images/serialLogo.svg" alt=""/>&nbsp;&nbsp;0x3eb75f6c5413623704
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tablecell}>
                                                <div className={classes.methodCell}>
                                                    Remove Swap Liqu...
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tablecell}>
                                                43124710
                                            </TableCell>
                                            <TableCell className={classes.tablecell}>
                                                14 secs ago
                                            </TableCell>
                                            <TableCell>
                                                <div className={classes.flexSerial} style={{ gap: "5rem" }}>
                                                    <p className={classes.tablecell}>0x543251d6400bb2d10d</p>
                                                    <img src="/images/span.btn.svg" alt=""/>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tablecell}>
                                                <div className={classes.flexSerial} style={{ gap: "5px" }}>
                                                    <img src="/images/copy.svg" alt="" onClick={() => copyTextToClipboard(textToCopy)}/>
                                                    <p className={classes.tablecell}>Context</p>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tablecell} style={{ color: "#111111" }}>
                                                0 MATIC
                                            </TableCell>
                                            <TableCell className={classes.tablecell} style={{ color: "#77838F" }}>
                                                0.02053344
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.tablecell}>
                                                <div className={classes.flexSerial}>
                                                    <img src="/images/serialLogo.svg" alt=""/>&nbsp;&nbsp;0x3eb75f6c5413623704
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tablecell}>
                                                <div className={classes.methodCell}>
                                                    Remove Swap Liqu...
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tablecell}>
                                                43124710
                                            </TableCell>
                                            <TableCell className={classes.tablecell} style={{ color: "#111111" }}>
                                                14 secs ago
                                            </TableCell>
                                            <TableCell>
                                                <div className={classes.flexSerial} style={{ gap: "5rem" }}>
                                                    <p className={classes.tablecell}>0x543251d6400bb2d10d</p>
                                                    <img src="/images/span.btn.svg" alt=""/>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tablecell}>
                                                <div className={classes.flexSerial} style={{ gap: "5px" }}>
                                                    <img src="/images/copy.svg" alt="" onClick={() => copyTextToClipboard(textToCopy)}/>
                                                    <p className={classes.tablecell}>Context</p>
                                                </div>
                                            </TableCell>
            
                                            <TableCell className={classes.tablecell} style={{ color: "#111111" }}>
                                                0 MATIC
                                            </TableCell>
                                            <TableCell className={classes.tablecell} style={{ color: "#77838F" }}>
                                                0.02053344
                                            </TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Box>
            </Grid>
        </>
    )
}

export default Transaction