import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  imgSection: {
    "& img": {
      width: "105px",
      innerHeight: "90px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
    },

    "@media(max-width:991px)": {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
  },
}));
const Logo = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
    <Box className={classes.imgSection}>
        <img
          onClick={() => history.push("/")}
          src="/images/logo1.svg"
          alt="Logo"
          style={{ cursor: "pointer" }}
          {...props}
        />
    </Box>
    </>
  );
};

export default Logo;
