import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeIndex from "../src/views/pages/Home";
import BlockIndex from "../src/views/pages/blocks"
import TransactionIndex from "../src/views/pages/transactionHistory"


const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomeIndex} />
        <Route exact path="/blocks" component={BlockIndex} />
        <Route exact path="/transaction-history" component={TransactionIndex} />
        {/* <Route component={NotFound} /> */}
      </Switch>
    </Router>
  );
};

export default Routes;  