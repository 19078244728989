import React from 'react'
import {
  Grid,
  makeStyles,
  Box,
  Toolbar,
  IconButton,
  Typography,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  Paper,
  TableBody,
  TableRow,
  LinearProgress
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  title: {
    flex: '1 1 100%',
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#6C757E",
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    background: "#FFFFFF",
    border: "1px solid rgba(231, 234, 243, 0.2)",
    boxShadow: "0px 8px 20px rgba(65, 65, 65, 0.2)",
    borderRadius: " 8px"
  },
  mainBox: {
    backgroundColor: "#FFFFFF",
    // height:"560px"
    marginBottom: "10rem"
  },
  absolutePosition: {
    position: "relative",
    bottom: "2rem",
    padding: "0 5%"
  },
  iconBorder: {
    borderRadius: "5px"
  },
  blocks: {
    display: "flex",
    justifyContent: "center"
  },
  tableContainer: {
    background: "#FFFFFF",
    border: "1px solid #E7EAF3",
    borderBottom: "none",
    width: "100%",
    borderTop: "none",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)"
  },
  tableHead: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#111111",
    textAlign: "center"
  },
  tablecell: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#D64F49",
    textAlign: "center"
  },

  tableDtaFlex: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  tableTDInnerFlex: {
    display: "flex",
    flexDirection: "column",
    "& p": {
      fontFamily: 'Poppins',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#D64F49",
      margin: "0"
    },
    "& span": {
      fontFamily: 'Poppins',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "11px",
      lineHeight: "17px",
      color: "#77838F"
    }
  },
  tableSymbol: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "0px",
    color: "#111111",
    background: "rgba(119, 131, 143, 0.1)",
    borderRadius: "5.6826px",
    width: "44.4px",
    height: "44.4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  transactionSymbol: {
    borderRadius: "22px"
  },
  tableTDMiddleFlex: {
    display: "flex",
    flexDirection: "column",
    "& p": {
      fontFamily: 'Poppins',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#111111",
      margin: "0"
    },
  },
  lastBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "18px",
    color: "#77838F",
    background: "rgba(119, 131, 143, 0.1)",
    borderRadius: "5.07375px",
    height: "39px"
  },
  btnouterdiv: {
    border: "1px solid #E7EAF3",
    borderRadius: "0px 0px 7px 7px",
    padding: " 10px",
    background: "#FFFFFF",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)"

  },
  blockBtnDiv: {
    background: "#D64F49",
    borderRadius: " 4px",
    textAlign: "center"
  },
  blockbtn: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "11px",
    lineHeight: "18px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 0",
    cursor: "pointer"
  },
  btnUpperouterdiv: {
    background: "#FFFFFF",
    border: "1px solid #E7EAF3",
    borderRadius: "8px 8px 0px 0px",
    padding: "0 15px",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)"
  },

  flexSpan: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px"
  },
  colorPrimary: {
    background: "#E7EAF3",
    width: "100%",
    borderRadius: "5px"
  },
  barColorPrimary: {
    background: "#D64F49"
  },
  colorPrimary2: {
    background: "#E7EAF3",
    width: "100%",
    borderRadius: "5px"
  },
  barColorPrimary2: {
    background: "#DB9A04"
  }
}));

const Blocks = () => {
  const classes = useStyles();

  const EnhancedTableToolbar = () => {

    return (
      <Toolbar
      >
        <Typography className={classes.title} id="tableTitle" component="div">
          Block #43124659 to #43124683 (Total of 43,124,684 blocks)
        </Typography>
        <IconButton className={classes.iconBorder}>
          <Typography>First</Typography>
        </IconButton>
        <IconButton className={classes.iconBorder}>
          <img src="/images/leftIcon.svg" alt=""/>
        </IconButton>
        <IconButton className={classes.iconBorder}>
          <Typography>Page 1 of 1724988</Typography>
        </IconButton>
        <IconButton className={classes.iconBorder}>
          <img src="/images/rightIcon.svg" alt="" />
        </IconButton>
        <IconButton className={classes.iconBorder} style={{ color: "#D64F49" }}>
          <Typography>Last</Typography>
        </IconButton>
      </Toolbar>
    );
  };
  return (
    <>
      <Grid className={classes.mainBox}>
        <Box className={classes.absolutePosition}>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <EnhancedTableToolbar />
              <TableContainer>
                <Table arial-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        Block
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Age
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Txn
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Validator
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Gas Used
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Gas Limit
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Base Fee
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Reward
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Burnt Fees (MATIC)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tablecell}>
                        43124683
                      </TableCell>
                      <TableCell className={classes.tablecell}>
                        -
                      </TableCell>
                      <TableCell className={classes.tablecell}>
                        60
                      </TableCell>
                      <TableCell className={classes.tablecell}>
                        0x09385a960a2e0b6b45
                      </TableCell>
                      <TableCell>
                        <div className={classes.flexSpan}>
                          <span>(85.30%, +71%)</span>
                          <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} value={50} variant="determinate" />
                        </div>
                      </TableCell>
                      <TableCell className={classes.tablecell} style={{ color: "#111111" }}>
                        -
                      </TableCell>
                      <TableCell className={classes.tablecell} style={{ color: "#111111" }}>
                        111.73 Gwei
                      </TableCell>
                      <TableCell className={classes.tablecell} style={{ color: "#111111" }}>
                        -
                      </TableCell>
                      <TableCell>
                        <div className={classes.flexSpan}>
                          <span>(86.91%)</span>
                          <LinearProgress classes={{ colorPrimary: classes.colorPrimary2, barColorPrimary: classes.barColorPrimary2 }} value={50} variant="determinate" />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tablecell}>
                        43124683
                      </TableCell>
                      <TableCell className={classes.tablecell}>
                        -
                      </TableCell>
                      <TableCell className={classes.tablecell}>
                        60
                      </TableCell>
                      <TableCell className={classes.tablecell}>
                        0x09385a960a2e0b6b45
                      </TableCell>
                      <TableCell>
                        <div className={classes.flexSpan}>
                          <span>(85.30%, +71%)</span>
                          <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} value={50} variant="determinate" />
                        </div>
                      </TableCell>
                      <TableCell className={classes.tablecell} style={{ color: "#111111" }}>
                        -
                      </TableCell>
                      <TableCell className={classes.tablecell} style={{ color: "#111111" }}>
                        111.73 Gwei
                      </TableCell>
                      <TableCell className={classes.tablecell} style={{ color: "#111111" }}>
                        -
                      </TableCell>
                      <TableCell>
                        <div className={classes.flexSpan}>
                          <span>(86.91%)</span>
                          <LinearProgress classes={{ colorPrimary: classes.colorPrimary2, barColorPrimary: classes.barColorPrimary2 }} value={50} variant="determinate" />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Box>
      </Grid>
    </>
  )
}

export default Blocks