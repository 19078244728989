import React from 'react';
import Nav from "../../../component/nav";
import Footer from "../../../component/footer";
import Blocks from "./blocks"

const BlockIndex = () => {
  return (
  <>
    <Nav />
    <Blocks />
    <Footer />
  </>
  )
}

export default BlockIndex