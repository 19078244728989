import React,{ useState }  from "react";
import {
    makeStyles,
} from '@material-ui/core';





const useStyles = makeStyles((theme) => ({

    searchBtn: {
        background: "#D64F49",
        border: "1px solid #D64F49",
        borderRadius: "0px 5px 5px 0px",
        height: "48px",
        width: "44px",
        marginLeft: "-1px",
        cursor:"pointer",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",

    },
    searchInput: {
        width: '540px',
        background: "#FFFFFF",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        height: "45px",
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "00.1em",
        color: "#8C98A4",
        paddingLeft: "20px",
        border:'none'
    }
}));


const SearchBar = () => {
    const classes = useStyles();
    const [term, setTerm] = useState('')

    function isAddress(address) {
        return /^(0x)?[0-9a-f]{40}$/i.test(address);
    }
    function isTx(address) {
        return /^(0x)?[0-9a-f]{64}$/i.test(address);
    }

    function search(term) {

        //if the term is an integer, load the block page
        if (term.match(/^[0-9]+$/)) {
            console.log(`Searching for block: ${term}`);
            window.location.href = `/block/${term}`;

        } else if (isAddress(term)) {
            console.log(`Searching for address: ${term}`);
            window.location.href = `/address/${term}`;

        } else if (isTx(term)) {
            console.log(`Searching for tx: ${term}`);
            window.location.href = `/tx/${term}`;

            //else if ens name
        } else if (term.match(/^[a-z0-9]+\.[a-z0-9]+$/i)) {
            console.log(`Searching for ens name: ${term}`);
            window.location.href = `/ens/${term}`;

            //else if token name
        } else if (term.match(/^[a-z0-9]+$/i)) {
            console.log(`Searching for token: ${term}`);
            window.location.href = `/token/${term}`;

        } else {
            console.log(`Not in bd: ${term}`);
        }

    }

    function onInputChange(term) {
        //console.log(term)
        setTerm(term);
    }

    return (
        <div className="search-bar" style={{
            display: "flex",
            alignItems: "center"
        }}>
            <input
                className={classes.searchInput}
                value={term}
                onChange={event => onInputChange(event.target.value)}
                placeholder="Search by Address / Txn Hash / Block / Token / Domain Name"
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        search(term)
                    }
                }}
            /> <button className={classes.searchBtn} onClick={() => search(term)}><img src="/images/searchIcon.svg" alt=""/></button>
        </div>
    );
}
export default SearchBar;
