import React from 'react';
import Nav from "../../../component/nav";
import Footer from "../../../component/footer";
import Transaction from "./transactionHistory"

const TransactionIndex = () => {
  return (
  <>
    <Nav />
    <Transaction />
    <Footer />
  </>
  )
}

export default TransactionIndex