import React from 'react';
import {
  Box,
  makeStyles,
  Container, Grid
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  footerBack: {
    background: "#FFFFFF"
  },
  LogoFlex: {
    display: "flex",
    gap: '10px',
    "& p": {
      fontFamily: 'Poppins',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "15.911px",
      lineHeight: "24px",
      color: "#0B0B0B"
    }
  },
  partition: {
    opacity: "0.2",
    border: " 0.9092px solid #E7EAF3"
  },
  terms: {
    fontFamily: 'Poppins !important',
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "17px !important",
    color: "#D64F49 !important",
    width: "48px !important",
    textAlignLast: "end !important"
  },
  termsFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center",
    "& p": {
      fontFamily: 'Poppins',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "11px",
      lineHeight: "19px",
      color: "#0B0B0B"
    }
  },
  termsEnd: {
    display:"flex",
    gap:"10px"
  }
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <footer className={classes.footerBack}>
        <Container >
          <Grid>
            <Grid>
              <Box>
                <div className={classes.LogoFlex}>
                  <img src="/images/footer-logo1.svg" alt=""/>
                  <p>Powered by POW Blockchain</p>
                </div>
              </Box>

              <div className={classes.partition}></div>
              <Box className={classes.termsFlex}>
                <p>POWBlockchain © 2023</p>
                <div className={classes.termsEnd}><p className={classes.terms}>Terms of Service
                </p>
                  <img src="/images/emailbox1.svg" alt=""/>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  )
}

export default Footer