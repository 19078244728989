import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Logo from "./Logo";
import SearchBar from "./SearchBar";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundImage: `url(${"/images/header-background.png"})`,
        padding: "13px 52px",
        backgroundSize: "cover"
    },
    homePara: {
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "00.1em",
        color: "#D64F49"
    },
    mainFlex: {
        display: "flex",
        justifyContent: "space-between"
    },
    searchBar: {
        backgroundImage: `url(${"/images/header.svg"})`,
        height: "231px"
    },
    searchFlx:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "66px"
    }
}));



const Header = () => {
    const classes = useStyles();


    return (
        <AppBar position="static" classes={{ root: classes.appBar }}>
            <Toolbar className={classes.mainFlex}>
                <Logo />
                <Typography className={classes.homePara}>
                    Home
                </Typography>

            </Toolbar>
            <div className={classes.searchBar}>
                <div className={classes.searchFlx}>
                    <SearchBar />
                </div>
            </div>
        </AppBar>
    );
};

export default Header;