import React from 'react';
import Routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
  <ToastContainer />
  <Routes />
  </>
  )
};

export default App;